import React, { CSSProperties, FC, useMemo } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Sep } from 'components/common/styled/common-styled';
import ResourceGroupHero from 'components/Resource/ResourceGroupHero';
import InfoIcon from 'assets/bx-info-circle';
import ResourceSessionSelector from 'components/ResourceSessionSelector/ResourceSessionSelector';
import ResourceGroupList from 'components/Resource/ResourceGroupList';
import ResourceGroupHeadline from 'components/Resource/ResourceGroupHeadline';
import ResourceGroupIncludedItems from 'components/Resource/ResourceGroupIncludedItems';
import { tabletBreakpoint, useIsMobile } from 'WindowDimensionProvider';
import { ResourceGroup, Resource } from 'types/listings';
import { PrimaryButton } from 'components/theme/Button';
import {
  ANALYTICS_EVENT,
  ANALYTICS_PROPERTY,
  AVAILABLE_BRAND_FEATURES,
} from '@kouto/types';
import { getProductFromResourceGroups } from 'features/analytics/services/analytics.helpers';
import { usePageViewEvent } from 'hooks/usePageViewEvent';
import ResourceGroupCancellationPolicy from 'components/Resource/ResourceGroupCancellationPolicy';
import useBrandToggleFeature from 'components/BrandToggleFeature/use-brand-toggle-feature';
import { SessionTimeOption } from '../hooks/useSessionTimeOptions';
import useResourceBookingSession from '../hooks/useResourceBookingSession';
import {
  ResourceGroupResourceListSkeleton,
  ResourceGroupSessionSelectorSkeleton,
} from '../pages/Skeleton';

interface Props {
  resourceGroup: {
    id: string;
    description: string;
    headline: string;
    headlineTags: string[];
    price?: number;
    title: string;
    heroImage: ResourceGroup['pictures'][number];
    groupListItems: Array<{ id: string; name: string; number: string }>;
    includedItems: Array<string>;
    experiences: Resource[];
  };
  sessionsTimeOptions: SessionTimeOption[];
  selectedDate: string;
  selectedTime: string;
  selectedDuration: number;
  availableDates: string[];
  isLoadingAvailableDates: boolean;
  onMonthChange: (date: moment.Moment) => void;
  onDateChange: (date: string) => void;
  onSessionChange: (time: string, duration: number) => void;
  onResourceChange: (resourceId: string) => void;
  onReserveClick: () => void;
  canReserve: boolean;
  categoryName?: string;
  collectionName?: string;
  collectionId?: string;
  collectionBrandId?: string;
}

const ResourceGroupLayout: FC<Props> = ({
  resourceGroup,
  sessionsTimeOptions,
  selectedDate,
  selectedTime,
  selectedDuration,
  availableDates,
  isLoadingAvailableDates,
  onMonthChange,
  onDateChange,
  onSessionChange,
  onResourceChange,
  onReserveClick,
  canReserve,
  categoryName,
  collectionName,
  collectionId,
  collectionBrandId,
}) => {
  const isMobileView = useIsMobile();
  const { t: translate } = useTranslation();
  const { sessions, date, time, duration, isLoading, cancellationPolicy } =
    useResourceBookingSession();

  const currentSession = useMemo(
    () =>
      sessions.find(
        (session) =>
          session.startDateTime === `${date}T${time}` &&
          session.duration === duration,
      ),
    [sessions, date, time, duration],
  );

  usePageViewEvent({
    eventName: ANALYTICS_EVENT.VIEW_EXPERIENCE_DETAIL,
    payload: {
      [ANALYTICS_PROPERTY.ExperienceId]: resourceGroup?.id,
      [ANALYTICS_PROPERTY.ExperienceTitle]: resourceGroup?.title,
      [ANALYTICS_PROPERTY.Products]: JSON.stringify(
        getProductFromResourceGroups({
          groups: [resourceGroup],
          categoryName,
          session: currentSession,
        }),
      ),
    },
    optionalPayload: {
      [ANALYTICS_PROPERTY.ExperienceCategory]: categoryName,
      [ANALYTICS_PROPERTY.ListId]: collectionId,
      [ANALYTICS_PROPERTY.ListName]: collectionName,
    },
    isNotReady: !resourceGroup?.id || !currentSession,
  });

  const vibesEnabled = useBrandToggleFeature(
    AVAILABLE_BRAND_FEATURES.ENABLE_VIBES_V2,
  );

  return (
    <>
      <Wrapper className="resource-mapped-page">
        {isMobileView ? null : (
          <Wrapper className="resource-mapped-page__hero">
            <ResourceGroupHero
              displayedCapacity={
                resourceGroup.experiences?.[0].displayedCapacity
              }
              price={Math.min(
                ...(currentSession?.priceTiers.map((e) => e.price) || []),
              )}
              title={resourceGroup.title}
              heroImage={resourceGroup.heroImage}
              collectionBrandId={collectionBrandId}
            />
          </Wrapper>
        )}
        <Wrapper
          className="resource-mapped-page__session-selector"
          marginBottom={24}
          marginTop={24}
        >
          {isLoading ? (
            <ResourceGroupSessionSelectorSkeleton />
          ) : (
            <ResourceSessionSelector
              sessionsTimeOptions={sessionsTimeOptions}
              selectedDate={selectedDate}
              selectedTime={selectedTime}
              selectedDuration={selectedDuration}
              isLoadingAvailableDates={isLoadingAvailableDates}
              availableDates={availableDates}
              onMonthChange={onMonthChange}
              onChangeSelectedDate={onDateChange}
              onChangeSelectedSession={onSessionChange}
            />
          )}
        </Wrapper>
        {(!sessionsTimeOptions.length ||
          !resourceGroup.groupListItems.length) &&
          !isLoading && (
            <Wrapper marginBottom={16}>
              <NoSessionsMessage isMobile={isMobileView}>
                <InfoIcon />
                <span>
                  {!sessionsTimeOptions.length
                    ? translate('noAvailableSessionsOnThisDate')
                    : translate('noGroupAvailableOnThisDateAndTime', {
                        groupTitle: resourceGroup.title,
                      })}
                </span>
              </NoSessionsMessage>
            </Wrapper>
          )}
        <Wrapper className="resource-mapped-page__resource-list">
          {isLoading ? (
            <ResourceGroupResourceListSkeleton />
          ) : (
            <ResourceGroupList
              truncateLimit={7}
              resourceList={resourceGroup.groupListItems}
              onResourceListItemClick={(resourceId) => {
                onResourceChange(resourceId);
              }}
            />
          )}
          <ActionButtonWrapper>
            <PrimaryButton
              aria-label={translate('reserveSelectedSlot')}
              big
              disabled={isLoading || !canReserve}
              onClick={onReserveClick}
              className="checkout-button"
            >
              {translate('reserve')}
            </PrimaryButton>
          </ActionButtonWrapper>
        </Wrapper>
        <Wrapper
          className="resource-mapped-page__sep"
          marginTop={48}
          marginBottom={48}
          mobileMarginTop="0px"
          mobileMarginBottom="20px"
        >
          <Sep />
        </Wrapper>
        <Wrapper className="resource-mapped-page__headline">
          <ResourceGroupHeadline
            headline={resourceGroup.headline}
            maxLines={4}
            vibes={vibesEnabled ? resourceGroup.headlineTags : undefined}
          />
        </Wrapper>
        {resourceGroup.includedItems.length > 0 ? (
          <>
            <Wrapper
              className="resource-mapped-page__sep"
              marginTop={48}
              marginBottom={48}
            >
              <Sep />
            </Wrapper>
            <Wrapper className="resource-mapped-page__included">
              <ResourceGroupIncludedItems
                includedItems={resourceGroup.includedItems}
              />
            </Wrapper>
          </>
        ) : null}

        {cancellationPolicy && cancellationPolicy.active && (
          <>
            <Wrapper
              className="resource-mapped-page__sep"
              marginTop={48}
              marginBottom={48}
            >
              <Sep />
            </Wrapper>
            <Wrapper className="resource-mapped-page__cancellation-policy">
              <ResourceGroupCancellationPolicy
                cancellationPolicy={cancellationPolicy}
              />
            </Wrapper>
          </>
        )}
      </Wrapper>
    </>
  );
};

export const Wrapper = styled.div<{
  marginTop?: number;
  marginBottom?: number;
  mobileMarginTop?: CSSProperties['marginTop'];
  mobileMarginBottom?: CSSProperties['marginBottom'];
}>`
  ${({
    className,
    marginBottom,
    marginTop,
    mobileMarginBottom,
    mobileMarginTop,
  }) => `
    &.${className} {
      margin-top: ${marginTop ?? 0}px;
      margin-bottom: ${marginBottom ?? 0}px;
      

      @media all and (max-width: ${tabletBreakpoint}) {
        margin-top: ${mobileMarginTop};
        margin-bottom: ${mobileMarginBottom};
      }
    }
    
    &.resource-mapped-page__resource-list {
      @media all and (max-width: ${tabletBreakpoint}) {
        .resource-group-list__list-wrapper {
          display: flex;
          flex-wrap: nowrap;
          overflow-x: auto;
          padding-bottom: 20px;
          
          > * {
          min-width: 90px;
          }
        }
      }
    }
`}
`;

export const NoSessionsMessage = styled.div<{ isMobile: boolean }>`
  width: 100%;
  height: ${({ isMobile }) => (isMobile ? '45px' : '48px')};
  border: solid 0.5px var(--way-colors-borderColor);
  background-color: var(--way-palette-black-5);
  user-select: none;

  & * {
    display: inline-block;
  }

  & svg {
    margin: ${({ isMobile }) => (isMobile ? '12px' : '14px')};

    & path {
      stroke: none;
      fill: var(--way-colors-svgColor);
    }
  }

  & span {
    line-height: ${({ isMobile }) => (isMobile ? '45px' : '48px')};
    color: var(--way-colors-contrastColorShades-60);
    vertical-align: top;
  }
`;

export default ResourceGroupLayout;

const ActionButtonWrapper = styled.div`
  display: flex;
  width: '100%';
  gap: 16px;
  justify-content: space-between;
  margin-top: 24px;
`;
