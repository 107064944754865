import useSWR from 'swr';
import { get } from 'utils/axios';
import { PublicGetListingEndpoint, ListingVersion } from '@kouto/types';
import { Listing } from 'types/listings';
import useSupportedLanguage from 'utils/listingsHelpers/useSupportedLanguage';

const useOneListing = (listingId?: string, latest = false) => {
  let collectionApiUrl: string | null = null;
  const languageCode = useSupportedLanguage();

  if (listingId) {
    collectionApiUrl = PublicGetListingEndpoint.url({
      listingId,
      query: {
        version: latest ? ListingVersion.LATEST : ListingVersion.PUBLISHED,
        language: languageCode,
      },
    });
  }

  let listing: Listing | undefined;
  const {
    data: listingData,
    isLoading,
    error,
  } = useSWR(collectionApiUrl, get<{ data: Listing }>);
  if (!isLoading && listingData) {
    listing = listingData?.data;
  }

  return { listing, isLoading, error };
};

export default useOneListing;
