import React, { FC, useMemo, useState, useRef, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { CancellationPolicy, Listing, ResourceGroup } from 'types/listings';
import { IPriceTier } from 'types/experience.types';
import { useCurrencyFormatter } from 'hooks/useCurrencyFormatter';
import { currencyFormat } from 'utils';
import { useBrandCurrency } from 'hooks/useBrandCurrency';
import { ANALYTICS_EVENT, ANALYTICS_PROPERTY } from '@kouto/types';
import { getProductFromResourceGroups } from 'features/analytics/services/analytics.helpers';
import { analyticsManager } from 'features/analytics';
import toDateString from 'utils/date/toDateString';
import getEndOfMonth from 'utils/date/getEndOfMonth';
import useSessions from 'api/session/useSessions';
import fromMoment from 'utils/date/fromMoment';
import fromDateAndTimeString from 'utils/date/fromDateAndTimeString';
import toPrettyTime from 'utils/date/toPrettyTime';
import getLastEndingSession from 'utils/data/sessions/getLastEndingSession';
import getEndingDateForSession from 'utils/data/sessions/getEndingDateForSession';
import getEndingDateForFirstAvailableDateSession from 'utils/data/firstAvailableDate/getEndingDateForFirstAvailableDateSession';
import { NonMappedResourceGroupCard } from './NonMappedResourceGroupCard';

type PriceTier = IPriceTier & {
  id: string;
};

interface Props {
  cancellationPolicy?: CancellationPolicy;
  collection: Listing;
  group: ResourceGroup;
  isLoading: boolean;
  selectedDate?: Date;
  vibes?: string[];
}

export const NonMappedResourceGroupCardWrapper: FC<Props> = ({
  cancellationPolicy,
  collection,
  group,
  isLoading,
  selectedDate: pageSelectedDate,
  vibes,
}) => {
  const { t: translate } = useTranslation();
  const currency = useBrandCurrency();
  const { formattedNumber } = useCurrencyFormatter();

  const trackAnalyticsEvent = () => {
    if (collection) {
      analyticsManager.sendEvent(ANALYTICS_EVENT.CLICK_EXPERIENCE, {
        [ANALYTICS_PROPERTY.ExperienceId]: group?.id,
        [ANALYTICS_PROPERTY.ExperienceTitle]: group?.title,
        [ANALYTICS_PROPERTY.ExperienceCategory]: collection.category?.name,
        [ANALYTICS_PROPERTY.ListId]: collection.id,
        [ANALYTICS_PROPERTY.ListName]: collection.title,
        [ANALYTICS_PROPERTY.Products]: JSON.stringify(
          getProductFromResourceGroups({
            groups: [group],
            categoryName: collection.category?.name,
            session: hookSessions[0],
          }),
        ),
      });
    }
  };

  const handleOnReserveClick: MouseEventHandler<HTMLAnchorElement> = () => {
    trackAnalyticsEvent();
  };

  const [localSelectedDate, setLocalSelectedDate] = useState<Date>();
  const trackedPageSelectedDate = useRef(pageSelectedDate);

  if (
    pageSelectedDate &&
    pageSelectedDate.getTime() !== trackedPageSelectedDate.current?.getTime()
  ) {
    trackedPageSelectedDate.current = pageSelectedDate;
    setLocalSelectedDate(undefined);
  }

  const calculatedSelectedDate = localSelectedDate ?? pageSelectedDate;

  const [localSelectedMonth, setLocalSelectedMonth] = useState<
    Date | undefined
  >(calculatedSelectedDate);

  const hasDatePickerOpened = useRef(false);
  const [newDatePickerOpen, setNewDatePickerOpen] = useState(false);

  if (newDatePickerOpen && !hasDatePickerOpened.current) {
    hasDatePickerOpened.current = true;
  }

  const { sessions: hookSessions, isLoading: isSessionsLoading } = useSessions(
    collection.id,
    {
      from: new Date(),
      resourceGroupId: group.id,
      skip: !hasDatePickerOpened.current && !calculatedSelectedDate,
      to: getEndOfMonth(localSelectedMonth ?? calculatedSelectedDate),
    },
  );

  const sessionsForSelectedDate = calculatedSelectedDate
    ? hookSessions.filter((session) =>
        session.startDateTime.startsWith(toDateString(calculatedSelectedDate)),
      )
    : undefined;

  const lastSessionForSelectedDate = getLastEndingSession(
    sessionsForSelectedDate ?? [],
  );

  const lastEndingDateForSelectedDate = lastSessionForSelectedDate
    ? getEndingDateForSession(lastSessionForSelectedDate)
    : undefined;

  const lastEndingDateForFirstAvailableDate =
    getEndingDateForFirstAvailableDateSession(group.firstAvailableDate);

  const selectedSession = calculatedSelectedDate
    ? hookSessions.find((session) =>
        session.startDateTime.startsWith(toDateString(calculatedSelectedDate)),
      )
    : undefined;

  const sessionDateString = calculatedSelectedDate
    ? selectedSession?.startDateTime.split('T')[0]
    : group.firstAvailableDate?.scheduledDate;

  const sessionTimeString = calculatedSelectedDate
    ? selectedSession?.startDateTime.split('T')[1]
    : `${group.firstAvailableDate?.sessions[0].startTime}:00`;

  const sessionDurationSeconds = calculatedSelectedDate
    ? selectedSession?.duration
    : group.firstAvailableDate?.sessions[0].duration;

  const sessionDate =
    sessionDateString && sessionTimeString
      ? fromDateAndTimeString(sessionDateString, sessionTimeString)
      : undefined;

  const endSessionDate =
    lastEndingDateForSelectedDate ?? lastEndingDateForFirstAvailableDate;

  const { capacityLabel, initialPrice, resourceImage } = useMemo(() => {
    const priceTiers = hookSessions
      .flatMap<PriceTier>((e) => (e ? e.priceTiers ?? [] : []))
      .sort((a, b) => a.price - b.price);
    const startingPrice = priceTiers[0]?.price || 0;
    const displayedCapacity = Number(group.experiences?.[0]?.displayedCapacity);

    return {
      resourceImage: group.pictures[0],
      initialPrice: startingPrice,
      capacityLabel:
        displayedCapacity > 0
          ? `${displayedCapacity} ${
              displayedCapacity === 1
                ? translate('person')
                : translate('people')
            }`
          : '',
    };
  }, [hookSessions, group, translate]);

  const fromPrice = initialPrice
    ? currencyFormat(currency)(formattedNumber(initialPrice))
    : '';

  const handleIsDayBlocked = (day: moment.Moment) => {
    if (!hookSessions) return true;

    return !hookSessions.some((session) =>
      session.startDateTime.startsWith(toDateString(fromMoment(day))),
    );
  };

  const handleNewDatePickerDateChange = (date: moment.Moment | null) => {
    trackAnalyticsEvent();
    setLocalSelectedDate(date ? date.toDate() : undefined);
  };

  const handleMonthChange = (date: Date) => {
    setLocalSelectedMonth(getEndOfMonth(date));
  };

  const displayDate = sessionDate ?? pageSelectedDate;

  const noSessionForPageSelectedDate =
    pageSelectedDate && !isSessionsLoading && !selectedSession;

  return (
    <NonMappedResourceGroupCard
      cancellationPolicyText={cancellationPolicy?.notes}
      capacityCount={Number(group.experiences?.[0]?.displayedCapacity)}
      capacityLabel={capacityLabel}
      collectionId={collection.id}
      daySessionRangeFrom={sessionDate ? toPrettyTime(sessionDate) : undefined}
      daySessionRangeTo={
        endSessionDate ? toPrettyTime(endSessionDate) : undefined
      }
      description={group.description}
      detailHasMoreToShow={false}
      displayDate={displayDate}
      fromPrice={fromPrice}
      groupId={group.id}
      handleIsDayBlocked={handleIsDayBlocked}
      imageUriObject={resourceImage?.uri}
      isLoading={isLoading}
      newDatePickerOpen={newDatePickerOpen}
      onMonthChange={handleMonthChange}
      onNewDatePickerDateChange={handleNewDatePickerDateChange}
      onNewDatePickerOpenChange={setNewDatePickerOpen}
      onReserveClick={handleOnReserveClick}
      resourceGroupFullDetailDescription={group.description}
      resourceGroupFullDetailIncludedItems={group.included}
      sessionDuration={sessionDurationSeconds}
      sessionTime={sessionTimeString}
      title={group.title}
      unavailable={noSessionForPageSelectedDate}
      vibes={vibes}
    />
  );
};

export default NonMappedResourceGroupCardWrapper;
