import React, { CSSProperties, useState, useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ResponsiveFlex } from 'components/common/styled/common-styled';
import { CollectionTitle } from 'features/Reserve/components';
import moment from 'moment';
import { Route, useRouteMatch } from 'react-router-dom';
import useResourceBookingSession from 'features/Reserve/hooks/useResourceBookingSession';
import useAvailableDates from 'features/Reserve/hooks/useAvailableDates';
import { DATE_FORMAT } from 'utils';
import { tabletBreakpoint, useIsMobile } from 'WindowDimensionProvider';
import { NonMappedResourceGroupPage } from 'features/Reserve/pages/ResourceGroupPage/NonMappedResourceGroupPage';
import { AVAILABLE_BRAND_FEATURES } from '@kouto/types';
import useBrandToggleFeature from 'components/BrandToggleFeature/use-brand-toggle-feature';
import NonMappedResourceGroupCardWrapper from 'features/Reserve/components/NonMappedResourceGroupCardWrapper';
import DatePickerButton from 'components/DatePickerButton';
import getFirstStartingResourceGroup from 'utils/data/resourceGroup/getFirstStartingResourceGroup';
import { useBrandId } from 'AppProvider';
import useBrandById from 'api/brand/useBrandById';

export const NonMappedCollectionPage: React.FC = () => {
  const isMobileView = useIsMobile();
  const { t: translate } = useTranslation();
  const match = useRouteMatch();

  const { collection, latest, isLoadingSessions, cancellationPolicy } =
    useResourceBookingSession();

  const brandId = useBrandId();

  const { data } = useBrandById(collection?.brandId ?? null);
  const promoterBrand = collection?.brandId !== brandId ? data?.name : null;

  const [showDatePicker, setShowDatePicker] = useState(false);

  const [datePickerDate, setDatePickerDate] = useState<moment.Moment | null>(
    null,
  );

  const datePickerDateObject = datePickerDate?.toDate();

  const initialVisibleMonth = useMemo(() => {
    const firstResourceGroup = getFirstStartingResourceGroup(
      collection?.resourceGroups || undefined,
    );

    return moment(firstResourceGroup?.firstAvailableDate?.scheduledDate);
  }, [collection?.resourceGroups]);

  const [dateForCalendarAvailability, setDateForCalendarAvailability] =
    useState<moment.Moment | null>(initialVisibleMonth);

  const { availableDates } = useAvailableDates({
    from: dateForCalendarAvailability
      ?.clone()
      .startOf('month')
      .format(DATE_FORMAT),
    to: dateForCalendarAvailability?.clone().endOf('month').format(DATE_FORMAT),
    collectionId: collection?.id,
    latest,
    skip: !showDatePicker,
  });

  const vibesEnabled = useBrandToggleFeature(
    AVAILABLE_BRAND_FEATURES.ENABLE_VIBES_V2,
  );

  const handleDateChange = (newDate: moment.Moment | null) => {
    setDatePickerDate(newDate);
  };

  if (!collection) {
    return <h1>{translate('noCollectionFound')}</h1>;
  }

  const vibes = collection.vibes.map(({ name }) => name);

  const handleIsDayBlocked = (day: moment.Moment) => {
    const formattedDay = day.format('YYYY-MM-DD');
    return !availableDates.some((date) => date === formattedDay);
  };

  const handleMonthChange = (newDate: moment.Moment) => {
    setDateForCalendarAvailability(newDate);
  };

  const handleClearButtonClick = () => {
    setDatePickerDate(null);
  };

  return (
    <CollectionDetailsContainer gap="36px">
      <ResponsiveFlex
        flex={1}
        breakpoint={tabletBreakpoint}
        justifyContent="space-between"
        mobileOverride={{
          direction: 'column',
          alignItem: 'flex-start',
          justifyContent: 'stretch',
          gap: 12,
        }}
      >
        <div>
          <CollectionTitle allowMultipleLines>
            {collection.title}
          </CollectionTitle>
          {promoterBrand && (
            <BrandName>
              {translate('by')} {promoterBrand}
            </BrandName>
          )}
        </div>
        <DatePickerButton
          date={datePickerDate}
          initialVisibleMonth={() => dateForCalendarAvailability ?? moment()}
          isDayBlocked={handleIsDayBlocked}
          onClearButtonClick={handleClearButtonClick}
          onDateChange={handleDateChange}
          onNextMonthClick={handleMonthChange}
          onOpenChange={setShowDatePicker}
          onPrevMonthClick={handleMonthChange}
          open={showDatePicker}
          position={isMobileView ? 'bottom-right' : 'bottom-left'}
          showClearButton
        />
      </ResponsiveFlex>
      <ResponsiveFlex
        direction="column"
        alignItem="flex-start"
        justifyContent="flex-start"
        width="100%"
        gap={36}
      >
        {collection.resourceGroups
          ?.sort((a, b) => a.order - b.order)
          .map((group) => (
            <NonMappedResourceGroupCardWrapper
              cancellationPolicy={cancellationPolicy}
              collection={collection}
              group={group}
              isLoading={isLoadingSessions}
              key={group.id}
              selectedDate={datePickerDateObject}
              vibes={vibesEnabled ? vibes : undefined}
            />
          ))}
      </ResponsiveFlex>
      {isMobileView && (
        <Route
          exact
          path={`${match.path}/group/:groupId`}
          component={NonMappedResourceGroupPage}
        />
      )}
    </CollectionDetailsContainer>
  );
};

const CollectionDetailsContainer = styled.div<{ gap?: CSSProperties['gap'] }>`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  gap: ${(props) => props.gap || '24px'};
`;

export const BrandName = styled.p`
  color: var(--Text-Tertiary, rgba(0, 0, 0, 0.6));
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.016px;
  margin-top: 8px;
  margin-bottom: 0px;
`;
