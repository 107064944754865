/* eslint-disable default-case, consistent-return */
import {
  ListingKind,
  ListingPictureKind,
  MediaType,
  ProductLine,
} from '@kouto/types';
import { QUESTION_SCOPES } from 'types/custom-questions';
import {
  FormattedListingMedia,
  FormatterListingMediaImage,
  FormatterListingMediaVideo,
  Listing,
  ListingV2,
} from 'types/listings';
import { serializeParams } from 'utils';
import { getBiggerMediaPreviewLink } from './data/medias/getBiggerMediaPreviewLink';

export const getListingProductLine = (listing: ListingV2): ProductLine => {
  if (listing.productLine) return listing.productLine;

  switch (listing.kind) {
    case ListingKind.EVENT:
      return ProductLine.ACTIVATE;
    case ListingKind.RESOURCE:
      return ProductLine.RESERVE;
    case ListingKind.EXPERIENCE:
      return listing.hostedById ? ProductLine.HOST : ProductLine.RESERVE;
  }
};

export const getListingRouteV1 = (
  listing: Listing,
): {
  pathname: string;
  search: string;
} => {
  let pathname = '';
  let search = '';

  switch (listing.kind) {
    case ListingKind.EVENT:
      pathname = `/event/${listing.id}`;
      if (listing.dates && listing.dates[0]) {
        search = serializeParams({
          preselectedDate: listing.dates[0],
        });
      }
      break;
    case ListingKind.RESOURCE:
      pathname = `/collection/${listing.id}`;
      break;
    case ListingKind.EXPERIENCE:
      pathname = `/e/${listing.slug}`;
  }

  return {
    pathname,
    search,
  };
};

export const getListingRoute = (
  listing: ListingV2,
): {
  pathname: string;
  search: string;
} => {
  let pathname = '';
  let search = '';

  switch (listing.kind) {
    case ListingKind.EVENT:
      pathname = `/event/${listing.resourceGroupCollectionId}`;
      if (listing.dates && listing.dates[0]) {
        search = serializeParams({
          preselectedDate: listing.dates[0],
        });
      }
      break;
    case ListingKind.RESOURCE:
      pathname = `/collection/${listing.resourceGroupCollectionId}`;
      break;
    case ListingKind.EXPERIENCE:
      pathname = `/e/${listing.slug}`;
  }

  return {
    pathname,
    search,
  };
};

export const getProductPageTitle = ({
  product,
  translate,
}: {
  product?: ProductLine;
  translate: (label: string, options?: Record<string, string>) => string;
}): string => {
  if (!product) return translate('mainLandingPageTitle');

  switch (product) {
    case ProductLine.ACTIVATE:
      return translate('activateLandingPageTitle');
    case ProductLine.RESERVE:
      return translate('reserveLandingPageTitle');
    case ProductLine.HOST:
      return translate('hostLandingPageTitle');
  }
};

export const getProductCardButtonLabel = (
  product: ProductLine,
  translate: (label: string) => string,
): string => {
  switch (product) {
    case ProductLine.ACTIVATE:
      return translate('buyTickets');
    case ProductLine.RESERVE:
      return translate('reserve');
    case ProductLine.HOST:
      return translate('buyTickets');
  }
};

export const getProductCardLabel = (
  product: ProductLine,
  translate: (label: string) => string,
): string => {
  switch (product) {
    case ProductLine.ACTIVATE:
      return translate('activateProductCardTitle');
    case ProductLine.RESERVE:
      return translate('reserveProductCardTitle');
    case ProductLine.HOST:
      return translate('hostProductCardTitle');
  }
};

export const listingShouldGoThroughParticipantsPage = (
  listing?: Listing,
  selectedGroupId?: string,
  selectedExperienceId?: string,
) => {
  let hasCustomQuestions = false;
  let askPartiticpantsInfo = false;
  let addOns = [];

  if (listing) {
    const selectedGroup = listing.resourceGroups?.find(
      (rg) => rg.id === selectedGroupId,
    );

    addOns = [...listing.addOns, ...(selectedGroup?.addOns || [])];

    if (listing.kind === ListingKind.EXPERIENCE) {
      askPartiticpantsInfo = true;
      // hasCustomQuestions = listing.customQuestions.length // TODO there are no customQuestions on listing root at the moment
    }

    if (selectedGroup) {
      const selectedResource = selectedGroup.experiences.find(
        (r) => r.id === selectedExperienceId,
      );
      if (selectedResource) {
        hasCustomQuestions = !!selectedResource.customQuestions.find(
          (q) => !q.config.isDefault && q.config.isActive,
        );
        askPartiticpantsInfo =
          selectedResource.customQuestions[0].config.scope ===
          QUESTION_SCOPES.PARTICIPANT;
      }
    }
  }

  return {
    hasAddOns: addOns.length > 0,
    askPartiticpantsInfo,
    hasCustomQuestions,
  };
};

export const getMediumMediaPreviewLink = (media: Listing['medias'][number]) => {
  const mediaLinks = media.links.filter((l) => l.type === MediaType.IMAGE);
  return (
    mediaLinks.sort(
      (ml1, ml2) => parseInt(ml2.resolution, 10) - parseInt(ml1.resolution, 10),
    )?.[Math.floor(mediaLinks.length / 2)]?.url || ''
  );
};

export const getMediumMediaLink = (media: Listing['medias'][number]) => {
  const mediaLinks = media.links.filter((l) => l.type === media.type);
  return mediaLinks.sort(
    (ml1, ml2) => parseInt(ml2.resolution, 10) - parseInt(ml1.resolution, 10),
  )[Math.floor(mediaLinks.length / 2)]?.url;
};

export const getBiggerMediaLink = (media: Listing['medias'][number]) => {
  const mediaLinks = media.links.filter((l) => l.type === media.type);
  return mediaLinks.sort(
    (ml1, ml2) => parseInt(ml2.resolution, 10) - parseInt(ml1.resolution, 10),
  )[0]?.url;
};

const formatMediaImage = (
  media: Listing['medias'][number],
): FormatterListingMediaImage => {
  return {
    id: media.id,
    type: MediaType.IMAGE,
    kind: media.kind,
    urlDesktop: getBiggerMediaLink(media),
    urlMobile: getMediumMediaLink(media),
  };
};

const formatMediaVideo = (
  media: Listing['medias'][number],
): FormatterListingMediaVideo => {
  return {
    id: media.id,
    type: MediaType.VIDEO,
    kind: media.kind,
    urlDesktop: getBiggerMediaLink(media),
    urlMobile: getMediumMediaLink(media),
    previewDesktop: getBiggerMediaPreviewLink(media),
    previewMobile: getMediumMediaPreviewLink(media),
  };
};

export const formatListingMedia = (
  medias?: Listing['medias'],
): FormattedListingMedia[] => {
  const availableMedias = structuredClone(medias || [])
    .filter((media) => media.links.some((link) => link.type === media.type))
    .map((media) => ({
      ...media,
      links: media.links.filter(
        (link) => !Number.isNaN(parseInt(link.resolution, 10)),
      ),
    }));

  const coverMedia = availableMedias.find((media) => media.kind === 'cover');
  const otherMedias = availableMedias.filter((media) => media.kind !== 'cover');
  const orderedMedias = coverMedia
    ? [coverMedia, ...otherMedias]
    : availableMedias;
  return orderedMedias.flatMap((media) => {
    if (media.type === MediaType.IMAGE) {
      return formatMediaImage(media);
    }
    return formatMediaVideo(media);
  });
};

export const listingMediaIsVideo = (
  media: FormattedListingMedia,
): media is FormatterListingMediaVideo => media.type === MediaType.VIDEO;

export const getListingCoverUrl = (listing: Listing, isMobile: boolean) => {
  const formattedMedia = formatListingMedia(listing.medias || []);

  const coverImage = formattedMedia.find(
    (media) =>
      !listingMediaIsVideo(media) && media.kind === ListingPictureKind.COVER,
  );
  if (coverImage) {
    if (isMobile) {
      return coverImage.urlMobile;
    }
    return coverImage.urlDesktop;
  }

  const coverVideo = formattedMedia.find(
    (media) =>
      listingMediaIsVideo(media) && media.kind === ListingPictureKind.COVER,
  );
  if (
    coverVideo &&
    listingMediaIsVideo(coverVideo) &&
    coverVideo.previewMobile &&
    coverVideo.previewDesktop
  ) {
    if (isMobile) {
      return coverVideo.previewMobile;
    }
    return coverVideo.previewDesktop;
  }

  const firstImage = formattedMedia.find(
    (media) => !listingMediaIsVideo(media),
  );
  if (firstImage) {
    if (isMobile) {
      return firstImage.urlMobile;
    }
    return firstImage.urlDesktop;
  }

  return '';
};

export const getUniquePricesForFirstAvailableDate = (
  event: Listing | undefined,
) =>
  Array.from(
    new Set(
      event?.firstAvailableDate?.sessions.flatMap(({ priceTiers }) =>
        priceTiers.map((pt) => pt.price),
      ),
    ),
  ) || [];
