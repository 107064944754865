export const getSearchParams = (keys: string[]) => {
  const { href } = new URL(window.location.href);
  // Extract the part after `#!` if present
  const cleanUrl = href.includes('#!') ? href.split('#!')[1] : href;
  const queryString = cleanUrl.split('?')[1] || '';
  const params = new URLSearchParams(queryString);

  return keys.reduce((acc, key) => {
    const values = params.getAll(key);
    return {
      ...acc,
      [key]: values.length > 1 ? values : values[0] ?? undefined,
    };
  }, {} as Record<string, string | string[] | undefined>);
};
